import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Box, FormControl, Grid, Paper, ThemeProvider, Link } from '@mui/material'
import theme from './styles/theme'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { UserLogin } from './common/api/types'
import { loginRequest, userNotificationTokenRequest } from './common/store/actions/user/actions'

interface LoginFormInputs {
  user: string
  passw: string
}

export default function SignIn() {
  const { t } = useTranslation()
  const productName = process.env?.REACT_APP_PRODUCT_NAME
  const loginProductName = productName === 'HELP_IP' ? 'Help Ip System' : 'Paging System'
  const providerSignInImage = productName === 'HELP_IP' ? 'portada-hip.jpg' : 'portada-voceo.jpg'
  const providerName = process.env.REACT_APP_PROVIDER_NAME || 'Surix S.R.L'
  const providerUrl = process.env.REACT_APP_PROVIDER_URL || 'https://surix.net/'

  const dispatch = useDispatch()
  const { control, handleSubmit, getValues, reset } = useForm<LoginFormInputs>({})

  const androidToken = new URLSearchParams(window.location.search).get('android-token')

  const onSubmit = (data: LoginFormInputs) => {
    const userLogin: UserLogin = {
      user: data.user,
      passw: data.passw,
    }
    dispatch(loginRequest(userLogin))
    if (androidToken) {
      dispatch(
        userNotificationTokenRequest({
          token: androidToken,
          user: userLogin.user,
          password: userLogin.passw,
        }),
      )
    }
    reset({
      user: null,
      passw: null,
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={6} md={4} component={Paper} elevation={6} square>
          <Box p={'10%'} mt={'10vh'}>
            <Typography component="h1" variant="h5" textAlign="center">
              {loginProductName}
            </Typography>
            <FormControl fullWidth>
              <Box mt={4}>
                <Controller
                  name="user"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                    validate: () => {
                      const user = getValues('user')
                      if (user.trim() !== user) return t('check_empty')
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      data-e2e="tasks-user-fld"
                      label={t('user')}
                      autoComplete="user"
                      variant="outlined"
                      helperText={error ? error.message : null}
                      error={!!error}
                      size="medium"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box mt={4}>
                <Controller
                  name="passw"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                    validate: () => {
                      const passw = getValues('passw')
                      if (passw.trim() !== passw) return t('check_empty')
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      data-e2e="tasks-passw-fld"
                      label={t('passw')}
                      type="password"
                      autoComplete="passw"
                      variant="outlined"
                      helperText={error ? error.message : null}
                      error={!!error}
                      size="medium"
                      onChange={onChange}
                      value={value}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </FormControl>
            <Box mt={4}>
              <Button
                data-e2e="login"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => handleSubmit(onSubmit)()}
              >
                {t('log_in')}
              </Button>
            </Box>
          </Box>
          <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href={providerUrl}>
              {providerName}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={false}
          sm={6}
          md={8}
          sx={{
            backgroundImage: `url(${providerSignInImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'fit',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </ThemeProvider>
  )
}
