import { useDispatch } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
import { useSSE } from 'react-hooks-sse'
import { useTranslation } from 'react-i18next'
import { TModeView } from '../../types'
import { RoomFeedback } from '../types'
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material'
import { getStyle } from '../../components/DevicesViewStyle'
import { mqttRequest } from '../../common/store/actions/mqtt/actions'
import DraggableButton from './DraggableButton'

interface IProps {
  isDesktopMode: boolean
  onlyEvents: boolean
  isProfile: number
  username: string
  rooms: number[]
}

const RoomsViews = ({ isDesktopMode, onlyEvents, isProfile, username, rooms }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  // Hanldes events by profile
  const roomsHealthEvents = useSSE<RoomFeedback[]>('rooms_health', [])
  const roomsCleaningEvents = useSSE<RoomFeedback[]>('rooms_cleaning', [])
  const roomsServiceEvents = useSSE<RoomFeedback[]>('rooms_service', [])
  const roomsAdmissionEvents = useSSE<RoomFeedback[]>('rooms_admission', [])

  const roomsEventsByProfile = (profile: number) => {
    switch (profile) {
      case 1:
      case 2:
      case 3:
        return roomsHealthEvents
      case 4:
      case 6:
      case 7:
        return roomsAdmissionEvents
      case 5:
        return roomsCleaningEvents
      case 8:
        return roomsServiceEvents
      default:
        return roomsHealthEvents
    }
  }

  const [orderedRooms, setOrderedRooms] = useState<RoomFeedback[]>([])
  // const [isAlphabetical, setIsAlphabetical] = useState(false)

  const filteredAndSortedRooms = useMemo(() => {
    const roomsEvents = roomsEventsByProfile(isProfile)
    const filteredRooms = roomsEvents?.filter((room) => rooms?.includes(room.id))
    const sortedRooms = filteredRooms.sort((a, b) => a.orderId - b.orderId)
    console.log('sortedRooms:', sortedRooms)
    return sortedRooms
  }, [
    isProfile,
    roomsHealthEvents,
    roomsCleaningEvents,
    roomsServiceEvents,
    roomsAdmissionEvents,
    rooms,
  ])

  // Handles icon size //
  const [viewMode, setViewMode] = useState<TModeView>(isDesktopMode ? 'large-icon' : 'medium-icon')
  const deviceSize = useMemo(() => {
    switch (viewMode) {
      case 'large-icon':
        return isDesktopMode ? 120 : 90
      case 'medium-icon':
        return isDesktopMode ? 100 : 72
      case 'small-icon':
        return isDesktopMode ? 72 : 64
      case 'list':
        return 32
      default:
        return 120
    }
  }, [viewMode, isDesktopMode])

  const style = getStyle(viewMode)

  useEffect(() => {
    const list = [...filteredAndSortedRooms]
    console.log('list:', list)
    setOrderedRooms(list)
  }, [filteredAndSortedRooms])

  // Handles alphabetical order
  // useEffect(() => {
  //   const list = [...filteredAndSortedRooms]
  //   if (isAlphabetical) {
  //     list.sort((a, b) => a.name.localeCompare(b.name))
  //   }
  //   setOrderedRooms(list)
  // }, [isAlphabetical, filteredAndSortedRooms])

  // Hanldes roomOrder by drag & drop feature
  const reorderRooms = (draggedIndex: number, targetIndex: number) => {
    const updatedRooms = [...orderedRooms]
    const [removed] = updatedRooms.splice(draggedIndex, 1)
    updatedRooms.splice(targetIndex, 0, removed)
    setOrderedRooms(updatedRooms)
  }

  //Right menu
  const getRightMenu = (profile: number, room_id: number, roomNo: string) => {
    switch (profile) {
      case 1:
      case 2:
      case 3:
        return [
          {
            label: t('override_state'),
            onClick: () => {
              dispatch(
                mqttRequest({
                  topic: 'server_event/jnew/rcu',
                  payload: `{"new":"EoSX", "user":"${username}", "room_id":"${room_id}", "room_no":"${roomNo}"}`,
                }),
              )
            },
          },
        ]
      case 7:
        return [
          {
            label: t('occupied_room'),
            onClick: () => {
              dispatch(
                mqttRequest({
                  topic: 'server_event/jnew/rcu',
                  payload: `{"new":"EoSRR", "user":"${username}", "room_id":"${room_id}", "room_no":"${roomNo}"}`,
                }),
              )
            },
          },
        ]
      default:
        return []
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box border={'1px solid grey'} borderRadius="5px" padding={3}>
          {/* <Box>
            <Switch
              checked={isAlphabetical}
              onChange={() => setIsAlphabetical(!isAlphabetical)}
              color="primary"
              inputProps={{ 'aria-label': 'Order by name or order number' }}
            />
            <Typography variant="overline">
              {isAlphabetical ? t('alphabetical') : t('byOrderId')}
            </Typography>
          </Box> */}
          <Box>
            <Select
              label="View"
              defaultValue={viewMode}
              size="small"
              onChange={(event) => setViewMode(event.target.value as TModeView)}
              data-e2e="viewModeSelect"
            >
              <MenuItem value="large-icon">Large Icon</MenuItem>
              <MenuItem value="medium-icon">Medium Icon</MenuItem>
              <MenuItem value="small-icon">Small Icon</MenuItem>
              <MenuItem value="list">List</MenuItem>
            </Select>
          </Box>
          <Box
            data-e2e="device-list"
            display="grid"
            gridTemplateRows="repeat(auto-fill, minmax(120px, 1fr))"
            gap="10px"
            justifyContent="center"
            width="100%"
            mt={'2%'}
          >
            {filteredAndSortedRooms.length === 0 ? (
              <Typography variant="h6" color="text.secondary" align="center" p={20}>
                {t('no_items')}
              </Typography>
            ) : (
              <Box sx={isDesktopMode ? style.layoutStyle : style.mobileStyle} data-e2e="room-list">
                {orderedRooms.map((room, index) => (
                  <DraggableButton
                    key={room.id}
                    isProfile={isProfile}
                    room={room}
                    username={username}
                    deviceSize={deviceSize}
                    onlyEvents={onlyEvents}
                    viewMode={viewMode}
                    rightMenu={getRightMenu(isProfile, room.id, room.roomNo)}
                    index={index}
                    setOrderedRooms={setOrderedRooms}
                    reorderRooms={reorderRooms}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default RoomsViews
